export const environment = {
  production: false,
  backend: 'https://dev.jadeapi.com/api/jade-api',
  offersImagesPath: 'https://jadeservice.azurewebsites.net/MyOffers/',
  appKeyPusher: 'ae66c8d69598ef09329e',
  azulSite: 'https://pruebas.azul.com.do/PaymentPage/',
  azulMerchantId: '39038540035',
  azulApprovedUrl: 'https://jade-tamashi-dev.herokuapp.com/#/carrito?reference=',
  azulDeclinedUrl: 'https://jade-tamashi-dev.herokuapp.com/#/carrito?declined=true',
  azulCancelUrl: 'https://jade-tamashi-dev.herokuapp.com/#/carrito?canceled=true',
  cardnetUrl: 'https://lab.cardnet.com.do/servicios/tokens/v1/Scripts/PWCheckout.js',
  cardnetCaptureUrl: 'https://lab.cardnet.com.do/servicios/tokens/v1/Capture/',
  cardnetPublicKey: 'mfH9CqiAFjFQh_gQR_1TQG_I56ONV7HQ',
  cardnetPublicKeyAnonymous: 'mfH9CqiAFjFQh_gQR_1TQG_I56ONV7HQ'//TODO poner publica desarrollo anonima
};