import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { environment } from 'src/environments/environment';
import { AgmCoreModule } from '@agm/core';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EcommerceJadeGroupModule, CartModule, UserEcommerceModule } from 'ecommerce-jade-group-plugin';
import { NgZorroAntdModule, NZ_ICONS } from 'ng-zorro-antd';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { NZ_I18N, en_US } from 'ng-zorro-antd';
import { NgxMaskModule } from 'ngx-mask';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './shared/services/interceptor';
registerLocaleData(en);

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent
  ],
  entryComponents: [
    FooterComponent,
    HeaderComponent
  ],
  imports: [
    NgZorroAntdModule,
    EcommerceJadeGroupModule.forRoot({
      BackendUrl: environment.backend,
      RestaurantType: 2,
      RestaurantName: 'Tamashi',
      RestaurantLogoUrl: 'https://aplatanaonews.com/wp-content/uploads/2019/07/Foto-12-5-19-12-03-30-a.-m.-min-640x400.jpg',
      appKeyPusher: environment.appKeyPusher,
      hasDelivery: true,
      paymentGateway: 0,
      cardnetCaptureUrl: environment.cardnetCaptureUrl,
      cardnetPublicKey: environment.cardnetPublicKey,
      azulSite: environment.azulSite,
      azulMerchantId: environment.azulMerchantId,
      azulMerchantType: 'Comercio',
      azulApprovedUrl: environment.azulApprovedUrl,
      azulDeclinedUrl: environment.azulDeclinedUrl,
      azulCancelUrl: environment.azulCancelUrl,
      dolarRate: 54,
      hasEcommerceActivated: true,
    }),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    CoreModule,
    CartModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: true
    }),
    UserEcommerceModule,
    NgxMaskModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCKs181Jn6PWOEfsq42Gcuh1LeySnb1n4M',
      libraries: ['places']
    }),
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US }, { provide: NZ_ICONS, useValue: icons },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
