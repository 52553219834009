import { Injectable } from '@angular/core';
import { HttpHandler, HttpRequest } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class InterceptorService {

    private DeviceID: string;
    constructor(
    ) {
        this.DeviceID = localStorage.getItem('DeviceUUID')
    }


    addDeviceUid(request) {
        if (request.url.includes('opencagedata')) return request;
        if (this.DeviceID === null) return request;
        return request.clone({
            setHeaders: {
                DeviceUUID: this.DeviceID
            }
        });
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): any {
        return next.handle(this.addDeviceUid(request));
    }
}

