import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvocationService } from './services/common/invocation.service';

@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    providers: [
        InvocationService
    ]
})
export class CoreModule { }