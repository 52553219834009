import { Component } from '@angular/core';
import { GlobalService } from './shared/services/global.service';
import { Router } from '@angular/router';
import { GlobalLibraryService, EcommerceUtilsService, IdentifierService, MessagingService } from 'ecommerce-jade-group-plugin';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public showPopupCartPreview: boolean = false;
  public showPopupUserLogged: boolean = false;
  public showInvisibleDiv: boolean = false;

  ngOnInit() {
    this.identifier.GetIdentifier().then((deviceuuid) => {
      this.messagingService.requestPermission(deviceuuid);
      this.messagingService.receiveMessage();
    });
  }

  constructor(
    private identifier: IdentifierService,
    private messagingService: MessagingService,
    private globalService: GlobalService,
    private globalLibraryService: GlobalLibraryService,
    private router: Router,
    private utils: EcommerceUtilsService
  ) {

    this.globalService.onShowInvisibleDiv.subscribe(value => this.showInvisibleDiv = value);
    this.globalService.onToggleVisibilityCartPreviewPopup.subscribe((value) => this.showPopupCartPreview = value);
    this.globalService.onToggleVisibilityUserLoggedPopup.subscribe((value) => this.showPopupUserLogged = value);
  }

  UserLoggedOut() {
    this.globalService.onToggleVisibilityUserLoggedPopup.next(false);
    this.globalService.onClickInvisibleDiv();
    this.router.navigate(['/menu']);
  }

  GoToMyCurrentOrders() {
    this.globalService.onClickInvisibleDiv();
    this.router.navigate(['/ordenes-pendientes']);
  }
  
  onClickInvisibleDiv() {
    this.globalService.onClickInvisibleDiv();
  }
  GoToCartPage() {
    this.globalService.onToggleVisibilityCartPreviewPopup.next(false);
    this.router.navigate(['/carrito']);
    this.globalService.onClickInvisibleDiv();
  }
  public goToMenu = () => {
    this.router.navigate(['/menu']);
    this.globalService.onClickInvisibleDiv();
  }

  public goToProfile = () => {
    this.router.navigate(['/perfil/0']);
    this.globalService.onClickInvisibleDiv();
  }
  public goToAddress = () => {
    this.router.navigate(['/direcciones']);
    this.globalService.onClickInvisibleDiv();
  }
}
